import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/auth/login.vue";
import login from "../views/auth/login.vue";
import cognito from "@/plugins/aws/cognito";
import store from '../store'
import api from "../services/api";


Vue.use(VueRouter);


const requireAuth = (to, from, next) => {
  cognito
    .isAuthenticated()
    .then((/*session*/) => {
      // 認可取得apiを実行
      api.authorization().then((result) => {
        // アクセストークンをチェック
        if (result.status === 200) {
          // パスワードの有効期限内であれば画面へ遷移
          if (result.data.result.checkPasswordExpired == "true") {
            const role = result.data.result.role
            if (role == "admin" || role == "user") {
              next();
            } else {
              // 仮にアクセストークンのapiがエラーになった場合、ローカルのセッションを全部破棄する
              sessionReset();
              next("/auth/login")
            }
            // パスワードの有効期限切れであればパスワード再発行画面へ遷移
          } else if (result.data.result.checkPasswordExpired == "false") {
            next("/auth/passwordChange")
          }
        } else {
          // 仮にアクセストークンのapiがエラーになった場合、ローカルのセッションを全部破棄する
          sessionReset();
          next("/auth/login")
        }
      }).catch((err) => {
        // エラーログを出すこと
        console.log(err);
        // 仮にアクセストークンのapiがエラーになった場合、ローカルのセッションを全部破棄する
        sessionReset();
        next("/auth/login")
      });
      next();
    })
    .catch((/*session*/) => {
      sessionReset();
      next({
        path: "/auth/login",
        //query: { redirect: to.fullPath },
      });
    });
};

const requireAuthAdmin = (to, from, next) => {
  cognito
    .isAuthenticated()
    .then((/*session*/) => {
      // 認可取得apiを実行
      api.authorization().then((result) => {
        // アクセストークンをチェック
        if (result.status === 200) {
          // パスワードの有効期限内であれば画面へ遷移
          if (result.data.result.checkPasswordExpired == "true") {
            const role = result.data.result.role
            if (role == "admin") {
              next();
            } else if (role == "user") {
              next("/rebooter/view")
            } else {
              // 仮にアクセストークンのapiがエラーになった場合、ローカルのセッションを全部破棄する
              sessionReset();
              next("/auth/login")
            }
            // パスワードの有効期限切れであればパスワード再発行画面へ遷移
          } else if (result.data.result.checkPasswordExpired == "false") {
            next("/auth/passwordChange")
          }
        } else {
          // 仮にアクセストークンのapiがエラーになった場合、ローカルのセッションを全部破棄する
          sessionReset();
          next("/auth/login")
        }
      }).catch((err) => {
        // エラーログを出すこと
        console.log(err);
        // 仮にアクセストークンのapiがエラーになった場合、ローカルのセッションを全部破棄する
        sessionReset();
        next("/auth/login")
      });
    })
    .catch((/*session*/) => {
      sessionReset();
      next({
        path: "/auth/login",
        // query: { redirect: to.fullPath },
      });
    });
};

const requireAuthRoot = (to, from, next) => {
  cognito
    .isAuthenticated()
    .then((/*session*/) => {
      next("/rebooter/view");
    })
    .catch((/*session*/) => {
      next({
        path: "/auth/login",
        // query: { redirect: to.fullPath },
      });
    });
};

const requireAuthNotFound = (to, from, next) => {
  cognito
    .isAuthenticated()
    .then((/*session*/) => {
      next("/rebooter/view");
    })
    .catch((/*session*/) => {
      next("/auth/login");
    });
};

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    beforeEnter: requireAuthRoot,
  },
  {
    path: "/auth/login",
    name: "login",
    component: login,
  },
  {
    path: "/auth/logout",
    name: "logout",
    beforeEnter: (to, from, next) => {
      cognito.logout();
      next("/auth/login");
    },
  },
  {
    path: "/rebooter/view",
    name: "rebooter_view",
    beforeEnter: requireAuth,

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rebooter/view.vue"),
  },
  {
    path: "/rebooter/new",
    name: "rebooter_new",
    beforeEnter: requireAuthAdmin,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rebooter/new.vue"),
  },
  {
    path: "/rebooter/edit/:id",
    name: "rebooter_edit",
    beforeEnter: requireAuthAdmin,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rebooter/edit.vue"),
  },
  {
    path: "/rebooter/control/:id",
    name: "rebooter_control",
    beforeEnter: requireAuth,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rebooter/control.vue"),
  },
  {
    path: "/admin/user/view",
    name: "user_view",
    beforeEnter: requireAuthAdmin,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/user/view.vue"
      ),
  },
  {
    path: "/admin/user/edit/:id",
    name: "user_edit",
    beforeEnter: requireAuthAdmin,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/user/edit.vue"
      ),
  },
  {
    path: "/admin/user/new",
    name: "user_new",
    beforeEnter: requireAuthAdmin,
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/user/new.vue"
      ),
  },
  {
    path: "/log",
    name: "log",
    beforeEnter: requireAuth,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/log/log.vue"),
  },
  {
    path: "/auth/passwordReset",
    name: "passwordReset",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/passwordReset"),
  },
  {
    path: "/auth/passwordChange",
    name: "passwordChange",
    beforeEnter: requireAuth,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/passwordChange.vue"),
  },
  {
    path: "/*",
    name: "error",
    beforeEnter: requireAuthNotFound,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // local_storageから"userName", "accesstoken"の値を取得し、storeを更新
  store.commit('setAuthInfo', {
    "userName": localStorage.getItem("userName"),
    "accesstoken": localStorage.getItem("accesstoken"),
    "userId": localStorage.getItem("userId")
  });
  next()
})

function sessionReset() {
  cognito.logout();

  // api側ログアウト
  const params = {
    userId: store.state.authInfo.userId,
    userName: store.state.authInfo.userName,
  };

  api.logout(params);

  localStorage.clear();
}




export default router;
