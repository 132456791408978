<template>
  <div id="app">
    <div id="nav">
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}

</script>

<style>
</style>
