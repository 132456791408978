import axiosAPi, { getRequestConfig } from './common';

/**
 * リブーター取得
 * @returns 
 */
export const getRebooter = async (id) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: 'GET',
    url: `/rebooter/${id}`,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * リブーター登録
 * @returns 
 */
 export const postRebooterCreate = async (params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: 'POST',
    url: `/rebooter/create`,
    data: params
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * リブーター更新
 * @returns 
 */
 export const putRebooterUpdate = async (id, params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: 'PUT',
    url: `/rebooter/${id}/update`,
    data: params
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};


/**
 * リブーター操作
 * @returns 
 */
 export const postRebooterControl = async (id, params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: 'POST',
    url: `/rebooter/${id}/control`,
    data: params
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};
