import axios from "axios";

/**
 * Vueのコンポーネント直下から参照したい場合はこちらのaxiosプラグインを使用する
 */
export default class axiosObject {
    
    /**
     * 
     * @param {*} Vue 
     * @param {*} options 
     */
    static install = (Vue) => {
        Vue.prototype.$axios = axios.create({
            baseURL: location.origin + "/api",
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'json',
            timeout: 3000
        })
        
        // リクエストログ
        Vue.prototype.$axios.interceptors.request.use(
            function (config) {
              console.info(config)
              return config;
            },
            function (error) {
              console.error(error)
              return Promise.reject(error);
            }
        )
        
        // レスポンスログ
        Vue.prototype.$axios.interceptors.response.use(
            function (response) {
              console.info(response)
              return response;
            },
            function (error) {
              console.error(error)
              return Promise.reject(error);
            }
        )
    }
}
