import axiosAPi, { getRequestConfig } from './common';

/**
 * ユーザー詳細取得
 * @returns 
 */
export const getUser = async (id) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: `GET`,
    url: `/user/${id}`,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * ユーザー登録
 * @returns 
 */
 export const postUser = async (params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: `POST`,
    url: `/user/create`,
    data: params
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * ユーザー更新
 * @returns 
 */
 export const putUser = async (id, params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: `PUT`,
    url: `/user/${id}/update`,
    data: params
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * パスワード有効期限更新
 * @returns 
 */
 export const putUserPasswordExpiredAt = async (params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: `PUT`,
    url: `/user/password_expired/extend`,
    data: params,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};
