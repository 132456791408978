import Vue from 'vue'
import App from './App.vue'
import router from './router'
// bootstrap-vueからBootstrapVue,IconPluginをインポートする
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue' // add
// Bootstrap と BootstrapVueのCSSファイルをインポート（順序はBootstrap⇒BootstrapVue）
import 'bootstrap/dist/css/bootstrap.css' // add
import 'bootstrap-vue/dist/bootstrap-vue.css' // add
import store from './store'

// plugins用
import cognito from './plugins/aws/cognito'
import axios from './plugins/axios'



Vue.config.productionTip = false

// BootstrapVueをプロジェクト全体で利用できるようにする
Vue.use(BootstrapVue) // add
// BootstrapVueアイコンコンポーネントプラグインの使用
Vue.use(IconsPlugin) // add

console.log(`mode: `, process.env.VUE_APP_MODE);
console.log(`ver: `, process.env.VUE_APP_VER);

new Vue({
  router,
  cognito,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')
