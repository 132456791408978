<template>
  <div>
    <h3>リブトン</h3>

    <b-container
        class="d-flex justify-content-center"
        style="margin-left: 0; padding: 0; max-width: 100%"
     >
      <div class="d-flex align-items-center">
      <img :src="iconUrl" alt="icon" title="icon" width="100" height="100"/>
        <div v-if="speechBubbleUrl" style="b-inlineblck;">
          <img :src="speechBubbleUrl" alt="speechBubble" title="speechBubble" width="80" height="40"/>
        </div>
      </div>
    </b-container>

    <b-form @submit.prevent="login">
      <div class="container" v-show="showFlag[0]">
        <div class="row align-items-center">
          <div class="col-12 mt-3">メールアドレス</div>
          <div class="col-12 mt-1">
            <input
              type="email"
              required
              maxlength="100"
              placeholder="Mailaddress"
              v-model="mailaddress"
            />
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-12 mt-3">パスワード</div>
          <div class="col-12 mt-1">
            <input
              type="password"
              placeholder="Password"
              required
              maxlength="100"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%()*+,-./:;<=>?@^_`{|}~\[\]]).{8,}"
              title="8文字以上、小文字、大文字、数字、特殊記号を1文字以上含む。"
              v-model="password"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <router-link to="/auth/passwordReset"
            >パスワードをお忘れの方はこちら</router-link
          >
        </div>
        <div class="col-12 mt-3">
          <b-button variant="primary" type="submit">ログイン</b-button>
        </div>
      </div>
    </b-form>

    <b-form @submit.prevent="mfa">
      <div class="container" v-show="showFlag[1]">
        <div v-show="showFlag[2]">
          QRコードもしくは秘密鍵を認証アプリで読み取って下さい。<br />
          <strong class="text-danger"
            >この画面から遷移すると同じデータは再表示されません。</strong
          ><br /><br />
          秘密鍵:{{ this.secretCode }}<br /><br />
          <vue-qrcode
            v-if="qrCodeUrl"
            :value="qrCodeUrl"
            :options="option"
            tag="img"
          ></vue-qrcode>
        </div>

        <div class="row align-items-center">
          <div class="col-12 mt-3">認証コード</div>
          <div class="col-12 mt-1">
            <input
              type="tel"
              placeholder="認証コード"
              required
              pattern="(?=.*\d).{6,6}"
              maxlength="100"
              title="6桁の半角数字を入力してください"
              v-model="authenticationCode"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <b-button variant="primary" type="submit">認証</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "../../services/api";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import message from "../../message";

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      mailaddress: "",
      password: "",
      secretCode: "",
      authenticationCode: "",
      cognitoUser: "",
      firstLoginFlag: false,
      showFlag: [true, false, false],
      qrCodeUrl: "",
      option: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        margin: 10,
        scale: 2,
        width: 300,
        color: {
          dark: "#000000FF",
          light: "#FFFFFFFF",
        },
      },
    };
  },
  // 変数に対して初期値を入れる
  computed: {
    iconUrl(){
      console.log("iconUrl", process.env.VUE_APP_ICON)
      return process.env.VUE_APP_ICON
    },
    speechBubbleUrl(){
      console.log("speechBubbleUrl", process.env.VUE_APP_SPEECHBUBBLE)
      return process.env.VUE_APP_SPEECHBUBBLE
    },
    authenticatorTitle(){
      console.log("authenticatorTitle", process.env.VUE_APP_AUTHENTICATOR_TITLE)
      return process.env.VUE_APP_AUTHENTICATOR_TITLE
    }
  },
  methods: {
    /**
     * メールアドレスとパスワード設定時のログイン押下後に呼び出し
     */
    login: function () {
      /**
       * 認証処理
       */
      this.$cognito
        .login(this.mailaddress, this.password)
        .then((result) => {
          console.log(`cognito result: `, result);
          this.cognitoUser = result[0];
          this.secretCode = result[1];
          this.firstLoginFlag = result[2];
          this.checkFirstLogin();
        })
        .catch((err) => {
          if (
            err == "NotAuthorizedException: Incorrect username or password."
          ) {
            alert(message.E200);
          } else {
            // 認証情報不一致以外のエラーは一旦原文のままだす。
            alert(message.E400 + err);
          }
        });
    },

    /**
     * mfa認証処理
     */
    mfa: function () {
      this.$cognito
        .mfaAuthorization(
          this.authenticationCode,
          this.cognitoUser,
          this.firstLoginFlag
        )
        .then((result) => {
          console.log(`mfaAuthorization result: `, result);
          const accesstoken = result.accessToken.jwtToken;
          const idtoken = result.idToken.jwtToken;
          const refreshtken = result.refreshToken.token;
          const userName = result.accessToken.payload.username;

          this.authorization(
            idtoken,
            accesstoken,
            refreshtken,
            this.mailaddress,
            userName
          );
        })
        .catch((err) => {
          if (
            err ==
            "EnableSoftwareTokenMFAException: Code mismatch and fail enable Software Token MFA"
          ) {
            alert(message.E201);
          } else if (
            err == "CodeMismatchException: Invalid code received for user"
          ) {
            alert(message.E201);
          } else {
            alert(message.E400 + err);
          }
        });
    },

    /**
     * cognitoで取得した認証情報をbackend側へ連携
     */
    authorization(idtoken, accesstoken, refreshtoken, mailaddress, userName) {
      const params = {
        idtoken: idtoken,
        accesstoken: accesstoken,
        userName: userName,
        mailaddress: mailaddress,
        refreshtoken: refreshtoken,
      };

      // API呼び出し
      api
        .login(params)
        .then((result) => {
          if (result.status == 200) {
            // ユーザー情報及び権限をローカルストレージに保存
            localStorage.setItem("accesstoken", result.data.result.accesstoken);
            localStorage.setItem("userName", result.data.result.userName);
            localStorage.setItem("userId", result.data.result.userId);

            // store更新
            this.$store.commit("setAuthInfo", {
              userName: result.data.result.userName,
              accesstoken: result.data.result.accesstoken,
              userId: result.data.result.userId,
            });

            // パスワードの有効期限内であればリブーター一覧画面へ遷移
            if (result.data.result.checkPasswordExpired == "true") {
              this.$router.replace("/rebooter/view");

              // パスワードの有効期限切れであればパスワード再発行画面へ遷移
            } else if (result.data.result.checkPasswordExpired == "false") {
              this.$router.replace("/auth/passwordChange");

              // セッション削除
            } else {
              alert(JSON.stringify(result.data.result));
              localStorage.clear();
              // ログイン画面に遷移
              this.$router.go({ name: "login" });
            }
          } else {
            alert(JSON.stringify(result.data.result));
            localStorage.clear();
            // ログイン画面に遷移
            this.$router.go({ name: "login" });
          }
        })
        .catch((err) => {
          // ToDO:ここでキャッチされてもログインされてしまうので直す修正する必要あり。
          // エラーログを出すこと
          console.log(err);
          this.error = err;
        });
    },

    /**
     * 初回のログインか、QRコードの認証済みかを判定し、画面の表示を切り替え
     */
    checkFirstLogin() {
      if (this.firstLoginFlag == true) {
        // 初回認証登録
        this.qrCodeUrl = `otpauth://totp/${this.mailaddress}?secret=${this.secretCode}&issuer=${this.authenticatorTitle}`;
        this.showFlag = [false, true, true];
      } else {
        // アプリを利用した多要素認証用の6桁の数値を入力する画面を表示する
        this.showFlag = [false, true, false];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
