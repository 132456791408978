import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  //CognitoUserAttribute
} from 'amazon-cognito-identity-js'
import {
  Config,
  //CognitoIdentityCredentials,
  //CognitoIdentityServiceProvider
  //CognitoIdentity,

} from 'aws-sdk'

export default class Cognito {

  /**
   * 
   * @param {*} config 
   */
  configure(config) {
    if (config.userPool) {
      this.userPool = config.userPool
    } else {
      this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId
      })
    }
    Config.region = config.region
    this.options = config
  }

  /**
   * 
   * @param {*} Vue 
   * @param {*} options 
   */
  static install = (Vue, options) => {
    Object.defineProperty(Vue.prototype, '$cognito', {
      get() { return this.$root._cognito }
    })

    Vue.mixin({
      beforeCreate() {
        if (this.$options.cognito) {
          this._cognito = this.$options.cognito
          this._cognito.configure(options)
        }
      }
    })
  }

  /**
    * username, passwordでログイン
    */
  login(username, password) {
    const userData = { Username: username, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData)
    const authenticationData = { Username: username, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        // 認証成功
        onSuccess: (result) => {
          // 実際にはクレデンシャルなどをここで取得する(今回は省略)
          resolve(result)
        },

        // 認証失敗
        onFailure: (err) => {
          console.log(`err: `, err);
          reject(err)
        },

        //仮パスワードでの認証成功(初回ログイン時)
        newPasswordRequired: function () {
          cognitoUser.completeNewPasswordChallenge(password, {}, this);
        },


        mfaSetup: function () {
          // ユーザープールでMFAが有効化されていると発生します
          cognitoUser.associateSoftwareToken(
            {
              associateSecretCode: (result) => {
                resolve([cognitoUser, result, true])
              },
              onFailure: (err) => {
                reject(err)
              },
            });
        },

        totpRequired: function (secretCode) {
          resolve([cognitoUser, secretCode, false]);
        },

      })
    })
  }

  /**
  * mfa認証
  */
  mfaAuthorization(authenticationCode, cognitoUser, firstLoginFlag) {
    return new Promise((resolve, reject) => {
      if (firstLoginFlag == true) {
        cognitoUser.verifySoftwareToken(String(authenticationCode), "userDevice", {
          onSuccess: (result) => {
            resolve(result)
          },
          onFailure: (err) => {
            reject(err)
          }
        })
      } else {
        cognitoUser.sendMFACode(String(authenticationCode), {
          onSuccess: (result) => {
            resolve(result)
          },
          onFailure: (err) => {
            reject(err)
          }
        }, 'SOFTWARE_TOKEN_MFA')
      }
    })
  }

  /**
    * ログアウト
    */
  logout() {
    if (this.userPool.getCurrentUser()) {
      this.userPool.getCurrentUser().signOut()
    }
  }

  /**
   * 認証有無
   */
  isAuthenticated() {
    const cognitoUser = this.userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
      if (cognitoUser === null) {
        reject(cognitoUser)
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
        } else {
          if (!session.isValid()) {
            reject(session)
          } else {
            resolve(session)
          }
        }
      })
    })
  }

  /**
   * cognitoセッション情報取得
   */
  getCognitoSession() {
    const cognitoUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (cognitoUser === null) {
        console.log("cognitoセッション取得エラー")
        reject(cognitoUser)
      }
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log("cognitoセッション取得エラー")
          reject(err)
        } else {
          if (!session.isValid()) {
            console.log("cognitoセッション取得エラー")
            reject(session);
          } else {
            resolve(session);
          }
        }
      })
    })
  }

  /**
   * 認証コードの送信
   */
  sendVerificationCode(username) {

    const userData = { Username: username, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData)

    // call forgotPassword on cognitoUser
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          console.log("err:", err);
          reject(err)
        },
      });
    })
  }

  /**
   * パスワードリセット
   */
  confirmPassword(userName, newPassword, verificationCode) {

    const userData = { Username: userName, Pool: this.userPool }
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          console.log("err:", err);
          reject(err)
        },
      });
    })
  }

}




