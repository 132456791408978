import axiosAPi, { getRequestConfig } from "./common";

/**
 * リブーター一覧取得
 * @returns
 */
export const getRebooters = async (
  baseName,
  outletName,
  outletStatus,
  exclusionFlag
) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: "GET",
    url: `/rebooters/?shopName=${baseName}&outletName=${outletName}&outletStatus=${outletStatus}&exclusionFlag=${exclusionFlag}`,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * リブーター一括操作
 * @returns
 */
export const postRebootersBulkOperation = async (params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: "POST",
    url: `/rebooters/bulkOperation`,
    data: params,
    timeout: 10000,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};
