import axiosAPi, { getRequestConfig } from './common';

/**
 * ログイン
 * @returns 
 */
 export const login = async (params) => {
  const _axiosRequestConfig = {
    method: 'POST',
    url: '/auth/login',
    data: params,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * ログアウト
 * @returns 
 */
 export const logout = async (params) => {
  const _axiosRequestConfig = {
    method: 'POST',
    url: '/auth/logout',
    data:params
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};

/**
 * 認可取得
 * @returns 
 */

 export const authorization = async () => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: 'GET',
    url: '/auth',
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};
