export default {
    //E2xx認証エラー
    E200: 'E200:入力されたメールアドレスもしくはパスワードが間違っています。',
    E201: 'E201:入力された認証コードが違います。',
    E202: 'E202:新しいパスワードが一致しません。',

    //E4xx外部連携エラー
    //AWScognito関連のエラー
    E400: 'E400:',

    //I2xx認証系
    I200: 'I200:入力したメールアドレスに認証コードを送信しました。',
    I201: 'I201:パスワードを再設定しました。',
    I202: 'I202:パスワードを変更しました。',

}
