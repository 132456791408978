import axiosAPi, { getRequestConfig } from './common';

/**
 * ログダウンロード
 * @returns 
 */
export const downloadLogs = async (params) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const response = await axiosAPi({
    ...axiosRequestConfig,
    url: "/log",
    method: "POST",
    responseType: "blob",
    data:params,
  });

  return response;
};
