import axiosAPi, { getRequestConfig } from './common';

/**
 * ユーザー一覧取得
 * @returns 
 */
export const getUsers = async (userName, companyName, departmentName, includeInvalid) => {
  const axiosRequestConfig = await getRequestConfig({ auth: true });
  const _axiosRequestConfig = {
    ...axiosRequestConfig,
    method: 'GET',
    url: `/users/?userName=${userName}&companyName=${companyName}&departmentName=${departmentName}&includeInvalid=${includeInvalid}`,
  };
  const response = await axiosAPi(_axiosRequestConfig);
  return response;
};
