import axios from "axios";
import store from "../../store";

// axiosオブジェクト生成
const axiosAPi = axios.create({
  baseURL: location.origin + "/api",
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: 3000,
});

// リクエスト時
axiosAPi.interceptors.request.use((config) => {
  // console.log(`config: `, config);
  return config;
});

// レスポンス時
axiosAPi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // タイムアウトの場合
    const isTimeout = error.code === "ECONNABORTED";
    if (isTimeout) {
      error.response = {
        status: 504,
        data: "Gateway Timeout",
        statusText: "Gateway Timeout",
        headers: {},
        config: {},
      };
      return error.response;
    }

    // ネットワークエラー(サーバダウンしている場合)
    if (!error.response) {
      error.response = {
        status: 500,
        data: "Internal Server Error",
        statusText: "Internal Server Error",
        headers: {},
        config: {},
      };
      return error.response;
    }
    return error.response;
  }
);

export const getRequestConfig = async (reqConfig) => {
  const config = {
    headers: {},
  };

  if (reqConfig?.auth === true) {
    const access_token = store.state.authInfo.accesstoken;
    config.headers["zxy-rebooter-token"] = access_token;
  }

  return config;
};

export default axiosAPi;
